import React from "react";
import "./App.css";
import { getAuth } from "firebase/auth";
import { HandlerSupercollection, getHandlers } from "./handlers";
import {
  getNextPathnameNowThatUserLoggedIn,
  getPathnameFromState,
  removeStartingAndEndingSlash,
} from "./stateLogic/pathnames";
import { SignupMenu } from "./components/SignupMenu";
import { firebaseAuth } from "./externalTypeNamespaces";
import {
  AppProps,
  AppState,
  DEFAULT_SCREEN_STATES,
  ScreenStateKind,
  UserSummary,
} from "./state";
import { addDebugHooks } from "./addDebugHooks";
import {
  noOp,
  getUserSummary,
  getDefaultScreenStateFromPublicPathname,
  isPathnamePublic,
  getDefaultScreenFromPrivatePathname as getDefaultScreenStateFromPrivatePathname,
  isPathnamePrivate,
} from "./stateLogic";
import { LoginMenu } from "./components/LoginMenu";

export class App extends React.Component<AppProps, AppState> {
  removeOnAuthStateChangedListener: () => void;

  handlers: HandlerSupercollection;

  constructor(props: App) {
    super(props);

    this.bindMethods();

    this.state = {
      screen: { kind: ScreenStateKind.AwaitingAuth },
      existingEmailAddresses: [],
      malformedEmailAddresses: [],
      user: null,
    };

    this.removeOnAuthStateChangedListener = noOp;

    this.handlers = getHandlers(this);

    addDebugHooks(this);
  }

  render() {
    const { screen } = this.state;
    switch (screen.kind) {
      case ScreenStateKind.AwaitingAuth:
        return <div></div>;
      case ScreenStateKind.Home:
        return <div>TODO: Home</div>;
      case ScreenStateKind.About:
        return <div>TODO: About</div>;
      case ScreenStateKind.Faq:
        return <div>TODO: FAQ</div>;
      case ScreenStateKind.Login:
        return (
          <LoginMenu
            state={this.state}
            screen={screen}
            handler={this.handlers.login}
          />
        );
      case ScreenStateKind.Signup:
        return (
          <SignupMenu
            state={this.state}
            screen={screen}
            handler={this.handlers.signup}
          />
        );
      case ScreenStateKind.VerifyEmail:
        return (
          <div>
            <h2>Email verification required</h2>
            <p>
              <span style={{ fontWeight: "bold", color: "red" }}>
                First, click the below button.
              </span>{" "}
              Then check your email.
            </p>
            <button
              onClick={this.handlers.verifyEmail.onSendEmailButtonClicked}
            >
              Send email
            </button>
            <button onClick={this.handlers.verifyEmail.onSignoutButtonClicked}>
              Sign out
            </button>
          </div>
        );
      case ScreenStateKind.FindACourse:
        return <div>TODO: Find a course</div>;
      case ScreenStateKind.EnrollInPython:
        return <div>TODO: Enroll in Python</div>;
      case ScreenStateKind.Dashboard:
        return (
          <div>
            <h2>Dashboard</h2>
            <button onClick={this.handlers.dashboard.onSignoutButtonClicked}>
              Sign out
            </button>
          </div>
        );
      case ScreenStateKind.LearnPython:
        return <div>TODO: Learn Python</div>;
      case ScreenStateKind.PageNotFound:
        return (
          <div>
            <h2>404: Page not found.</h2>
            <button onClick={this.handlers.pageNotFound.onHomeButtonClicked}>
              Return home
            </button>
          </div>
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _exhaustiveCheck: never = screen;
  }

  componentDidMount(): void {
    this.removeOnAuthStateChangedListener = getAuth().onAuthStateChanged(
      this.onAuthStateChanged
    );

    window.addEventListener("popstate", this.onPopState);
  }

  componentWillUnmount(): void {
    this.removeOnAuthStateChangedListener();
    window.removeEventListener("popstate", this.onPopState);
  }

  onPopState(event: PopStateEvent): void {
    // TODO
    console.log("onPopState", event.state);
  }

  onAuthStateChanged(firebaseUser: firebaseAuth.User | null): void {
    if (firebaseUser === null) {
      this.handleAuthStateChangeAssumingUserNotLoggedIn();
    } else {
      const user = getUserSummary(firebaseUser);
      this.handleAuthStateChangeAssumingUserLoggedIn(user);
    }
  }

  handleAuthStateChangeAssumingUserNotLoggedIn(): void {
    const strippedPathname = removeStartingAndEndingSlash(
      window.location.pathname
    );

    if (isPathnamePublic(strippedPathname)) {
      this.replaceState({
        screen: getDefaultScreenStateFromPublicPathname(strippedPathname),
      });
      return;
    }

    if (isPathnamePrivate(strippedPathname)) {
      this.replaceState({
        screen: {
          ...DEFAULT_SCREEN_STATES.LOGIN,
          nextPathname: strippedPathname,
        },
      });
      return;
    }

    this.replaceState((oldState) => ({
      ...oldState,
      screen: DEFAULT_SCREEN_STATES.PAGE_NOT_FOUND,
    }));
  }

  handleAuthStateChangeAssumingUserLoggedIn(user: UserSummary): void {
    if (!user.isEmailVerified) {
      this.replaceState({
        screen: {
          kind: ScreenStateKind.VerifyEmail,
          user,
        },
        user,
      });
      return;
    }

    const strippedPathname = removeStartingAndEndingSlash(
      getNextPathnameNowThatUserLoggedIn(this.state, user)
    );

    if (isPathnamePublic(strippedPathname)) {
      this.replaceState({
        screen: getDefaultScreenStateFromPublicPathname(strippedPathname),
        user,
      });
      return;
    }

    if (isPathnamePrivate(strippedPathname)) {
      this.replaceState({
        screen: getDefaultScreenStateFromPrivatePathname(
          strippedPathname,
          user
        ),
        user,
      });
      return;
    }

    this.replaceState({
      screen: DEFAULT_SCREEN_STATES.PAGE_NOT_FOUND,
      user,
    });
  }

  replaceState(
    newStateOrStateUpdater:
      | Partial<AppState>
      | ((oldState: AppState) => AppState)
  ): void {
    this.setState((oldState) => {
      if (typeof newStateOrStateUpdater === "function") {
        return newStateOrStateUpdater(oldState);
      }
      return { ...oldState, ...newStateOrStateUpdater };
    }, this.replaceHistoryStateBasedOnAppState);
  }

  replaceHistoryStateBasedOnAppState(): void {
    const newPathname = getPathnameFromState(this.state);
    window.history.replaceState(null, "", newPathname);
  }

  bindMethods(): void {
    this.onAuthStateChanged = this.onAuthStateChanged.bind(this);
    this.onPopState = this.onPopState.bind(this);
    this.replaceHistoryStateBasedOnAppState =
      this.replaceHistoryStateBasedOnAppState.bind(this);
  }
}
